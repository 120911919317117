import { Component, ViewChild, Input } from '@angular/core';
import { VgAPI } from 'videogular2/core';
import { VgHLS } from 'videogular2/src/streaming/vg-hls/vg-hls';
import { IMediaStream } from '../../models';

@Component({
  selector: 'app-videogluar-playback',
  templateUrl: './videogluar-playback.component.html',
  styleUrls: ['./videogluar-playback.component.css']
})
export class VideogluarPlaybackComponent {

  @ViewChild(VgHLS) vgHls: VgHLS;

  @Input() currentStream: IMediaStream;

  api: VgAPI;
  constructor() { }


  onPlayerReady(api: VgAPI) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // Set the video to the beginning
        this.onPlayerReady(this.api);
        this.api.play();
        this.api.getDefaultMedia().currentTime = 1;
      }
    );
  }
}
