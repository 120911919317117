import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {

  submitted = false;
  changePasswordForm: FormGroup;
  isPasswordChanged = false;
  isError = false;
  message = false;
  // @Output() changePassword = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>, private formBuilder: FormBuilder,
    private authService: AuthService) { }

  ngOnInit() {
    this.buildChangePasswordForm();
  }

  toClose(): void {
    this.dialogRef.close();
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  buildChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  save() {
    this.submitted = true;
    console.log(this.changePasswordForm);
    if (this.changePasswordForm.valid) {
      this.message = false;
      if (this.changePasswordForm.value["newPassword"] === this.changePasswordForm.value["confirmPassword"]) {
        // this.changePassword.emit(this.changePasswordForm.value);
        const value = {
          username: window.sessionStorage.getItem('username'),
          password: this.changePasswordForm.value['newPassword']
        };
        this.authService.changePassword(value).subscribe((res) => { }, result => {
          this.isPasswordChanged = true;
          this.isError = false;
          this.message = false;
          this.toClose();
        });
      } else {
        this.isPasswordChanged = false;
        this.isError = false;
        this.message = true;
      }
    }
  }

}
