import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/*  importing ngx-translate and the http loader */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VgCoreModule } from "videogular2/core";
import { VgControlsModule } from "videogular2/controls";
import { VgOverlayPlayModule } from "videogular2/overlay-play";
import { VgBufferingModule } from "videogular2/buffering";
import { VgStreamingModule } from "videogular2/streaming";

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AlertComponent } from './components/alert/alert.component';

import { VideogluarComponent } from './components/videogluar/videogluar.component';
import { VideogluarPlaybackComponent } from './components/videogluar-playback/videogluar-playback.component';

import { SwfPlayerComponent } from './components/swf-player/swf-player.component';

import { LiveVideoService } from '../modules/video/live-video-service/live-video-service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [LiveVideoService],
  declarations: [HeaderComponent, FooterComponent, PageNotFoundComponent, AlertComponent, VideogluarComponent, VideogluarPlaybackComponent, SwfPlayerComponent],
  exports: [PageNotFoundComponent, HeaderComponent, FooterComponent, VideogluarComponent, VideogluarPlaybackComponent, SwfPlayerComponent]
})
export class SharedModule { }
