import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-forgot-username-password',
  templateUrl: './forgot-username-password.component.html',
  styleUrls: ['./forgot-username-password.component.css']
})
export class ForgotUsernamePasswordComponent implements OnInit {

  forgotUsernamePasswordForm: FormGroup;
  submitted = false;
  invalidLogIn = false;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.forgotUsernamePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  get f() {
    return this.forgotUsernamePasswordForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.forgotUsernamePasswordForm.valid) {
      this.router.navigate(['login']);
      /* this.authService.logIn(this.forgotUsernamePasswordForm.value).subscribe((data: any) => {
        if (data && data.result === 1) {
          this.invalidLogIn = false;
          this.authService.isLoggedIn = this.authService.isAuthenticated();
          this.router.navigate(['video']);
        } else {
          this.invalidLogIn = true;
        }
      }, err => {
        console.log(err);
        this.invalidLogIn = true;
      });
      console.log(this.loginForm.value); */
    }
  }

}
