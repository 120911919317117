import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth.service';
import { ChangePasswordDialogComponent } from '../../../components/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  host: {
    '(document: click)': 'hideDropdown($event)'
  }
})
export class HeaderComponent implements OnInit {

  @Input() isLoggedIn;
  @Input() isPasswordChanged = false;
  @Output() logOut = new EventEmitter();
  @Output() navigate = new EventEmitter();
  submitted = false;
  isResponsiveMenuCollapse = false;
  isActiveVideo = false;
  isActivePlayback = false;
  showDropDown = false;
  constructor(private router: Router,
    public authService: AuthService,
    private location: Location,
    public dialog: MatDialog) { }

  ngOnInit() {
  }


  ngOnChanges() {
    this.router.events.subscribe(x => {
      if (this.location.path() != '' && this.location.path() == '/video') {
        this.isActiveVideo = true;
        this.isActivePlayback = false;
      } else if (this.location.path() != '' && this.location.path() == '/playback') {
        this.isActiveVideo = false;
        this.isActivePlayback = true;
      }
    })
  }
  hideDropdown(event) {
    if (!event.target.classList.contains('dropdown-toggle')) {
      this.showDropDown = false;
    }
  }
  sessionOut() {
    this.showDropDown = false;
    this.logOut.emit('');
  }

  redirect(url) {
    let elements = document.querySelectorAll('ul.navbar-nav li'), i;
    for (i = 0; i < elements.length; ++i) {
      elements[i].classList.remove('active');
    }
    let element = document.getElementById(url);
    element.parentElement.classList.add('active');
    this.navigate.emit(url);
  }

  showVideoSelectionMenu() {
    if (document.getElementsByClassName('left-tree-wrapper')[0].classList.contains('slideIn')) {
      document.getElementsByClassName('left-tree-wrapper')[0].classList.remove('slideIn');
    } else {
      document.getElementsByClassName('left-tree-wrapper')[0].classList.add('slideIn');
    }
  }

  openModal() {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '600px'
    });
    this.showDropDown = false;
  }
}
