import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isChangePassword = false;

  constructor(public auth: AuthService, private translate: TranslateService, private router: Router) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.auth.isLoggedIn = this.auth.isAuthenticated();
    if (!this.auth.isLoggedIn) {
      this.router.navigate(['login']);
    }
  }

  logOut(event) {
    this.auth.logOut().subscribe(data => {
      if (data && data.result === 0) {
        this.auth.removeSession();
        this.auth.isLoggedIn = this.auth.isAuthenticated();
        this.router.navigate(['login']);
      }
    }, err => {
      console.error(err);
    })
  }

  navigate(event) {
    this.router.navigate([event]);
  }

  changePassword(event) {
    const params = event;
    params.sessionid = sessionStorage.getItem('sessionid');
    this.auth.changePassword(params).subscribe(data => {
      if (data && data.result === 1) {
        this.isChangePassword = true;
      }
    }, err => {
      this.isChangePassword = false;
      console.log(err);
    })
    console.log(event);
  }

  closeToast() {

  }
}
