import { Component, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/auth.service';
import { LoginResponse } from '../../shared/models'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  invalidLogIn = false;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['video']);
    }
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authService.logIn(this.loginForm.value).subscribe((data: LoginResponse) => {
        if (data && data.result === 1) {
          this.invalidLogIn = false;
          this.authService.isLoggedIn = this.authService.isAuthenticated();
          this.router.navigate(['video']);
        } else {
          this.invalidLogIn = true;
        }
      }, err => {
        console.log(err);
        this.invalidLogIn = true;
      });
    }
  }
}
