import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent {

  constructor(public dialogRef: MatDialogRef<NoDataComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  toClose(): void {
    this.dialogRef.close();
  }

}
