import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { WindowRef } from '../../../windowRef';
import { eventBus } from '../event-bus';
import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from '../../../components/error-dialog/error-dialog.component';
import { fromEvent, Subscription } from 'rxjs';
import { LiveVideoService } from '../../../modules/video/live-video-service/live-video-service';
@Component({
  selector: 'swf-player',
  templateUrl: './swf-player.component.html',
  styleUrls: ['./swf-player.component.css']
})
export class SwfPlayerComponent implements OnInit, AfterViewInit {
  @Input() url;
  @Input() type;
  @Input() videos;
  @Input() windowCount;
  @Input() height;
  @Input() width;
  @Input() window: WindowRef;

  selectedWindow: Number = 0;
  browser = "";
  isInitFinished = false;//Are video plug loading complete
  subscription: Subscription;

  constructor(private winRef: WindowRef, public dialog: MatDialog, private liveVideoService: LiveVideoService) {
    this.window = winRef.nativeWindow;
  }

  ngOnInit() {

    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      this.browser = 'Opera';
    }
    else if ((navigator.userAgent.indexOf("MSIE") != -1) || (navigator.userAgent.indexOf("Edge") > -1))//IF IE > 10
    {
      this.browser = 'IE';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      this.browser = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      this.browser = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      this.browser = 'Firefox';
    }
    else {
      this.browser = 'unknown';
    }
  }
  ngAfterViewInit() {
    console.log(this.url)
    let self = this;
    //Init Video Plug
    window['selectedWindow'] = 0;
    function initPlayerExample() {
      //Video plug-in init param
      let params = {
        allowFullscreen: "true",
        allowScriptAccess: "always",
        bgcolor: "#FFFFFF",
        wmode: "transparent",
        scale: "noScale"
      };
      //Init flash
      self.window['swfobject'].embedSWF("./assets/swf/player.swf", "cmsv6flash", '100%', '100%', "11.0.0", null, null, params, null);
      initFlash();
      self.subscription = self.liveVideoService.renderVideos().subscribe(self.renderVideos.bind(self));
      let videoSubscription = self.liveVideoService.renderVideo().subscribe(self.updateVideo.bind(self));
      self.subscription.add(videoSubscription);
    }
    //Are video plug loading complete
    function initFlash() {
      if (self.window['swfobject'].getObjectById("cmsv6flash") == null ||
        typeof self.window['swfobject'].getObjectById("cmsv6flash").setWindowNum == "undefined") {
        setTimeout(initFlash, 50);
      } else {

        //Stop Video
        self.window['swfobject'].getObjectById("cmsv6flash").stopVideo(0);

        // //Setting the language video widget
        self.window['swfobject'].getObjectById("cmsv6flash").setLanguage("./assets/swf/en.xml");
        // //First of all windows created
        // self.window['swfobject'].getObjectById("cmsv6flash").setWindowNum(36);
        // //Re-configure the current number of windows
        self.window['swfobject'].getObjectById("cmsv6flash").setWindowNum(self.windowCount);
        // //Set the video plug-in server

        //Set the video window title
        self.window['swfobject'].getObjectById("cmsv6flash").setVideoInfo(0, '');

        if (self.type == 'vod') {
          //Play Video
          self.window['swfobject'].getObjectById("cmsv6flash").startVod(0, self.url);
        } else {
          // Live video
          self.window['swfobject'].getObjectById("cmsv6flash").setServerInfo("13.126.69.77", "6605");
        }
        self.isInitFinished = true;

      }
    }

    //Video callback event
    self.window["onTtxVideoMsg"] = function (index, type) {
      console.log(index, type)
      if (type == "select") {
        window['selectedWindow'] = index;
      };
    }
    initPlayerExample();
  }

  renderVideos(devices: any) {
    var self = this;
    if (!this.isInitFinished) {
      setTimeout(function () {
        self.renderVideos(devices);
      }, 50);
      return;
    }
    console.log(devices);

    if (devices.length) {
      devices.forEach((device, index) => {
        if (index < self.windowCount) {
          //Set the video window title
          //console.log(typeof self.window['swfobject'].getObjectById("cmsv6flash").stopVideo);
          if (typeof self.window['swfobject'].getObjectById("cmsv6flash").stopVideo == "function") {
            self.window['swfobject'].getObjectById("cmsv6flash").stopVideo(index);
            self.window['swfobject'].getObjectById("cmsv6flash").setVideoInfo(index, device.name + "-" + device.channel_name);
            self.window['swfobject'].getObjectById("cmsv6flash").startVideo(index, "", device.device_id, parseInt(device.channel_name.substr(device.channel_name.length - 1, 1)) - 1, 1, true);
          } else {
            //self.openDialog("Something Went wrong, Try again!");
          }
        }
      });
    }
  }

  updateVideo(video: any) {
    var self = this;
    self.window['swfobject'].getObjectById("cmsv6flash").stopVideo(video.index);
    self.window['swfobject'].getObjectById("cmsv6flash").setVideoInfo(video.index, video.device.name + "-" + video.device.channel_name);
    self.window['swfobject'].getObjectById("cmsv6flash").startVideo(video.index, "", video.device.device_id, parseInt(video.device.channel_name.substr(video.device.channel_name.length - 1, 1)) - 1, 1, true);
  }

  openDialog(data: any) {
    this.dialog.open(ErrorDialogComponent, {
      width: '385px',
      data: data
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}