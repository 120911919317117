import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CONFIG } from './app.config';
import { map } from "rxjs/operators";
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  userDetails: any;

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  logIn(params: any): Observable<any> {
    return this.http.post(`${CONFIG.baseURL}${CONFIG.login}`, params)
      .pipe(map((res: Response) => this.setTokenAndUserDetails(res, params)),
        catchError(this.handleError));
  }

  logOut(): Observable<any> {
    return this.http.post(`${CONFIG.baseURL}${CONFIG.logOut}`, '').pipe(
      catchError(this.handleError));
  }

  openDialog(data: any) {
    this.dialog.open(ErrorDialogComponent, {
      width: '385px',
      data: data
    })
  }



  changePassword(params: any): Observable<any> {
    return this.http.post(`${CONFIG.baseURL}${CONFIG.changePassword}`, params).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError)
    );
  }

  setTokenAndUserDetails(res: any, params: any) {
    sessionStorage.setItem('sessionid', res.details.session_id);
    sessionStorage.setItem('username', params.username);
    return res;
  }

  getToken() {
    return sessionStorage.getItem('sessionid');
  }

  getUsername() {
    return sessionStorage.getItem('username');
  }

  removeSession() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('sessionid');
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    /* window.alert(errorMessage); */
    return throwError(errorMessage);
  }

  isAuthenticated() {
    return sessionStorage.getItem('sessionid') !== null
  }
}