import { Component, ViewChild, Input } from '@angular/core';
import { BitrateOption, VgAPI } from 'videogular2/core';
import { VgHLS } from 'videogular2/src/streaming/vg-hls/vg-hls';
import { IMediaStream } from '../../models';

@Component({
  selector: 'app-videogluar',
  templateUrl: './videogluar.component.html',
  styleUrls: ['./videogluar.component.css']
})
export class VideogluarComponent {

  @ViewChild(VgHLS) vgHls: VgHLS;

  @Input() currentStream: IMediaStream;
  @Input() timeChange: string;

  api: VgAPI;

  constructor() { }

  onPlayerReady(api: VgAPI) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // Set the video to the beginning
        console.log(1);
        this.api.getDefaultMedia().currentTime = 0;
      }
    );
  }
}
