import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LiveVideoService {
  
  private videos = new Subject<any>();
  private video = new Subject<any>();

  updateVideos(videos:Array<Object>) {
    this.videos.next(videos);
  }

  updateVideo(video:Object){
    this.video.next(video);
  }
  
  renderVideos():Observable<any> {
    return this.videos.asObservable();
  }

  renderVideo():Observable<any>{
    return this.video.asObservable();
  }


}